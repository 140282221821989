<template>
<div class="open_pdf">
<button class="open_but" @click="onClickLeft">返回</button>
  <pdf
      v-for="i in numPages"
      :key="i"
      :src="pdfUrl"
      :page="i"
      style="display: inline-block; width: 100%"
  ></pdf>
</div>
</template>

<script>
import pdf from 'vue-pdf'
export default {
  name: "openPdf",
  components: {
    pdf
  },
  data(){
    return{
      pdfUrl: '',
      src: '',
      numPages: '',
      name: ''
    }
  },
  mounted() {
    this.pdfUrl =  this.$route.query.url
    this.name =  this.$route.query.name
    this.vuePdf(this.$route.query.url)
  },

  methods:{
    vuePdf(url){
      let _this = this
      const loadingTask = pdf.createLoadingTask(url)
      pdf.createLoadingTask(url).promise.then(pdf=>{
        _this.pdfUrl = loadingTask
        _this.numPages = pdf.numPages;
      }).catch(()=>{
        console.log('pdf加载失败')
      })
    },
    onClickLeft(){
      this.$router.go(-1)
    }

  }
}
</script>

<style scoped lang="scss">
.open_pdf{

  .open_but{
    position: fixed;
    left: 10px;
    top: 10px;
    z-index: 9999;
    color: #323233;
    font-size: 12px;
    background-color: #fff;
    border: 1px solid #ebedf0;
    border-radius: 4px;
    padding: 3px 8px;
    //  margin: 10px;
  }
}
</style>
